import axios from "axios";

export function request(config) {
  const instance = axios.create({
    baseURL: 'http://122.51.221.44:9003',
    //baseURL: 'http://localhost:29187',
    timeout: 12000,
  })

  instance.interceptors.request.use(config => {
    return config
  }, error => {

  })

  instance.interceptors.response.use(config => {
    return config.data
  }, error => {

  })

  return instance(config)
}
